<template>

    <b-modal id="novo-ClienteModal" 
             scrollable
             ref="novoClienteModal" 
             centered :title="titulo" 
             class="titleModal" 
             size="lg"
             @hide="limparCampos()">

        <b-container>
            <!--#region <=== CAMPOS PRÉ DEFINIDOS DO CLIENTE ===> -->
            <b-col>
                <b-row>
                    <b-col class="data-field">
                        <label>Nome do cliente</label><br />
                        <input type="text" v-model="nomeCliente" />
                    </b-col>
                    
                </b-row>
            </b-col>
            
            
            <b-col>
                <b-row>
                    <b-col class="data-field">
                        <label>CNPJ / CPF </label><br />
                        <input type="text"  v-model="cnpj_cpfCliente"/>
                    </b-col>
                    <b-col class="data-field">
                        <label>Telefone</label><br />
                        <input type="text"  v-model="telefoneCliente" />
                    </b-col>
                </b-row>
            </b-col>
            
            
            <b-col>
                <b-row>
                    <b-col class="data-field">
                        <label>E-mail</label><br />
                        <input type="text"  v-model="emailCliente" />
                    </b-col>
                    <b-col class="data-field">
                        <label>Endereço</label><br />
                        <input type="text"  v-model="enderecoCliente"/>
                    </b-col>
                </b-row>
            </b-col>
            
            
            <b-col>
                <b-row>
                    <b-col class="data-field">
                        <label>Estado</label><br />
                        <input type="text"  v-model="estadoCliente" />
                    </b-col>
                    <b-col class="data-field">
                        <label>Cidade</label><br />
                        <input type="text"  v-model="cidadeCliente" />
                    </b-col>
                </b-row>
            </b-col>
            <!--#endregion-->
            <!-- CAMPOS PERSONALIZADOS =======================-->
            <b-col>
                <center  v-if="!dadosComplementos">
                    <br>
                    <i class="fas fa-circle-notch fa-spin carregandoContt mx-2" 
                    small >
                    </i>
                    <label class="carregandoContt"> Carregando ...</label>
                </center>
                <b-row v-for="(dado, i) in dadosComplementos" class="a">
                    <b-col class="data-field">
                        <label>{{dado.NomeCampo}}</label><br />
                        <div v-if="dado.TipoCampo == 0">
                            <input type="text" v-model="dado.Resposta"/>
                        </div>
                        <div v-else-if="dado.TipoCampo == 3">
                            <select v-model="dado.RespostaOpcaoId">
                                <option v-for="(op, i) in dado.ClientesComplementoOpcao" :value='op.Id'>
                                    {{op.NomeOpcao}}
                                </option>
                            </select>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row id="containerContatoPrincipal" v-if="clienteId">
                    <br>
                    <h5 class="data-field" id="tituloCttPrincipal"><b>Contato Principal</b></h5>
                    <b-col class="data-field">
                        <label>Nome</label>
                        <input type="text" v-model="contatoPrincipal.Nome">
                        <label>Email</label>
                        <input type="text" v-model="contatoPrincipal.Email">
                    </b-col>
                    <b-col class="data-field">
                        <label>Telefone</label>
                        <input type="text" v-model="contatoPrincipal.Telefone">
                        <label>Cargo</label>
                        <input type="text" v-model="contatoPrincipal.Cargo">
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row v-for="(contato,index) in contatos" class="caixaContato">
                    <br>
                    <b-col class="data-field">
                        <label>Nome</label>
                        <input type="text" v-model="contato.Nome">
                        <label>Email</label>
                        <input type="text" v-model="contato.Email">
                        <div class="caixaContatoPrincipal">
                            <input type="checkbox" class="checkPrincipal" @change="verificaContatoPrincipal($event,index)" v-model="contato.ContatoPrincipal"> 
                            <label class="labelContato">Contato principal</label>
                        </div>
                    </b-col>
                    <b-col class="data-field">
                        <label>Telefone</label>
                        <input type="text" v-model="contato.Telefone">
                        <label>Cargo</label>
                        <input type="text" v-model="contato.Cargo">
                        <div class="caixaExclusao">
                            <label class="labelExclusao" @click="removerContato(index)">Excluir contato</label>
                        </div>
                    </b-col>
                </b-row>
                <br v-if="contatos.length == 0">
                <button class="addButtom data-field"
                        id="add-option-btn"
                        @click="adicionarContato">
                    <b>Adicionar contato</b>
                </button>
            </b-col>
        </b-container>

        <!--BOTOES FOOTER MODAL =================================================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light" @click="cancelar()" class="rounded-0">Cancelar</b-button>

            <b-button id="botaoSalvar" variant="success" @click="postarCliente" :disabled="isSending" class="rounded-0">
                <span v-if="isSending">
                    <b-spinner small class="mr-1"></b-spinner>
                    Salvando
                </span>
                <span v-else>
                    Salvar
                </span>
            </b-button>

        </template>

    </b-modal>

</template>

<!--=======================================================================================================-->

<script>
    import axios from 'axios'

    export default {
        name: 'novoClienteModal',
        
        data() {
            return {
                dadosComplementos: null,
                isSending: false,
                nomeCliente: null,
                clienteId: null,
                cnpj_cpfCliente: null,
                telefoneCliente: null,       
                contatos: [],
                emailCliente: null,
                enderecoCliente: null,
                contatoPrincipal: {Nome: '', Telefone: '', Email: '', Cargo: '', Id: null, ContatoPrincipal: true},
                estadoCliente: null,
                retorno: false,
                cidadeCliente: null,
                complementos: [],
                lixosIds: [],
                complementoCliente: []
            }
        },

        created() {

            //this.carregaComplementos();

        },
        computed: {
            camposPreenchidos() {
                return (
                    this.emailCliente ||
                    this.telefoneCliente ||
                    this.cnpj_cpfCliente
                );
            },
            titulo(){
                if(this.clienteId){
                    return "Editar Cliente";
                }
                else{
                    return 'Novo cliente';
                }
            }
        },

        methods: {
            carregaComplementos() {
                $("#caixa_carregamento").show();
                axios.get("/api/v2/clientes/BuscarConfiguracoes")
                    .then((response) => {
                        this.dadosComplementos = [];
                        response.data.forEach(comp => {
                            this.dadosComplementos.push({
                                ClientesComplementoOpcao: comp.ClientesComplementoOpcao, 
                                NomeCampo: comp.NomeCampo,
                                TipoCampo: comp.TipoCampo,
                                ClientesConfigComplementoId: comp.Id,
                                Resposta: this.clienteId? this.complementos.find(c => c.ClientesConfigComplementoId === comp.Id)?.Resposta ?? null : null,
                                RespostaOpcaoId: this.clienteId ? this.complementos.find(c => c.ClientesConfigComplementoId === comp.Id)?.RespostaOpcaoId ?? null : null,
                                Id: this.clienteId ? this.complementos.find(c => c.ClientesConfigComplementoId === comp.Id)?.Id ?? null : null
                            });
                        });
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            adicionarContato(){
                this.contatos.push({Nome:'',Telefone:'',Email:'',Cargo:'', ContatoPrincipal: false,Id: null});
            },
            iniciarModal(cliente,nome) {  
                if(cliente){
                    this.cnpj_cpfCliente = cliente?.CnpjCpf ?? null;
                    this.nomeCliente = cliente?.RazaoSocial ?? cliente?.Nome ?? null;
                    this.telefoneCliente = cliente?.Telefone ?? null;
                    this.emailCliente = cliente?.Email ?? null;
                    this.clienteId = cliente?.Id ?? null;
                    this.complementos = cliente.Complementos;
                    this.buscarContatos(this.clienteId);
                }
                if(nome) this.nomeCliente = nome;
                this.carregaComplementos();
                this.$bvModal.show("novo-ClienteModal");
                const promise1 = new Promise(resolve => this.promiseSalvaContato = resolve);
                const promise2 = new Promise(resolve => this.promiseSalvaCliente = resolve);
                const promise3 = new Promise(resolve => this.promiseEditaContato = resolve);
                const promise4 = new Promise(resolve => this.promiseDeletaContato = resolve);
                return Promise.all([promise1, promise2, promise3,promise4]).then(() => {
                    this.isSending = false;
                    this.$refs["novoClienteModal"].hide();
                    this.limparCampos();
                });
            },
            alteraContatoPrincipal(contatoTarget, index) {
                console.log(this.contatoPrincipal)
                console.log(contatoTarget);
                const { Email, Telefone, Cargo, Nome, Id } = this.contatoPrincipal;

                
                this.$set(this.contatos, index, {                    
                    Email,
                    Telefone,
                    Cargo,
                    Nome,
                    Id,
                    ContatoPrincipal: false
                 });

                this.contatoPrincipal = {
                    Email: contatoTarget.Email,
                    Telefone: contatoTarget.Telefone,
                    Cargo: contatoTarget.Cargo,
                    Nome: contatoTarget.Nome,
                    Id: contatoTarget.Id,
                    ContatoPrincipal: true
                };
                console.log(this.contatoPrincipal)
            },
            verificaContatoPrincipal(event, index){
                if(this.clienteId){
                    if(event.target.checked){
                        this.$bvModal.msgBoxConfirm("É permitido apenas um contato principal por cliente. Deseja tornar este o contato principal?", {
                            title: 'Atenção',
                            okTitle: 'Confirmar',
                            cancelTitle: 'Cancelar',
                            okVariant: 'success',
                            cancelVariant: 'white',
                            centered: true
                        }).then(confirmado => {
                            if (confirmado) {
                                this.alteraContatoPrincipal(this.contatos[index], index);
                            }
                            this.contatos.forEach(ctt => {
                                ctt.ContatoPrincipal = false;
                            })
                        })
                    }
                }
                else{
                    if(event.target.checked){
                        this.$bvModal.msgBoxConfirm("É permitido apenas um contato principal por cliente. Deseja tornar este o contato principal?", {
                            title: 'Atenção',
                            okTitle: 'Confirmar',
                            cancelTitle: 'Cancelar',
                            okVariant: 'success',
                            cancelVariant: 'white',
                            centered: true
                        }).then(confirmado => {
                            if (confirmado) {
                                this.contatos.forEach(ctt => {
                                    if(this.contatos.indexOf(ctt) != index){
                                        ctt.ContatoPrincipal = false;
                                    }
                                })
                            }
                        })
                    } 
                }
            },
            verificaContatos(){
                const verificacao = (contato) => (!contato.Email || contato.Email == '') && (!contato.Telefone || contato.Telefone == '');
                if(this.contatos.length > 0) return this.contatos.some(verificacao);
                return false;
            },
            postarCliente() {
                this.isSending = true;
                if (!this.camposPreenchidos) {

                    this.$bvModal.msgBoxOk(`Pelo menos um dos campos "Telefone, CNPJ/CPF ou Email", tem que ser preenchidos.`, {
                        title: "Atenção",
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "Ok",
                        centered: true,
                    })
                    this.isSending = false;
                } 
                else if(this.verificaContatos()){
                    this.$bvModal.msgBoxOk(`Pelo menos um dos campos "Email/Telefone", tem que ser preenchidos nos contatos.`, {
                        title: "Atenção",
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "Ok",
                        centered: true,
                    })
                    this.isSending = false;
                }
                else {
                    var ContatosSemId = this.contatos.filter(item => !item.Id).map(item => {
                        delete item.Id;
                        return item;
                    });
                    let ContatosComId = this.contatos.filter(item => item.Id).map(item => {
                        return item
                    });
                    if(this.contatoPrincipal.Id) ContatosComId.push(this.contatoPrincipal);
                    else ContatosSemId.push(this.contatoPrincipal);

                    if(this.clienteId){
                        let dadosEdita = {
                            Cliente: {
                                Id: this.clienteId,
                                CnpjCpf: this.cnpj_cpfCliente,
                                RazaoSocial: this.nomeCliente,
                                Telefone: this.telefoneCliente,
                                Email: this.emailCliente,
                                Endereco: this.enderecoCliente,
                                UF: this.estadoCliente,
                                Municipio: this.cidadeCliente,
                            },
                            Complementares: this.dadosComplementos
                        }          

                        axios.put(`api/v2/clientes/EditarCliente`,dadosEdita).then(response => {
                            this.promiseSalvaCliente(response.data);
                        }).catch(error => {
                            console.error(error);
                        })
                        if(ContatosSemId.length > 0){
                            if(!this.contatoPrincipal.Id) ContatosComId.push(this.contatoPrincipal);
                            axios.post(`api/v2/clientes/SalvarContatos/${this.clienteId}`,ContatosSemId).then(() => {
                                this.promiseSalvaContato(true);
                            }).catch(error => {
                                console.error(error);
                            })
                        }
                        else {
                            this.promiseSalvaContato(true);
                        }

                    }
                    else{
                        this.promiseSalvaContato(true);
                        let dadosCliente = {
                            CnpjCpf: this.cnpj_cpfCliente,
                            RazaoSocial: this.nomeCliente,
                            NomeFantasia: this.nomeCliente,
                            Telefone: this.telefoneCliente,
                            Email: this.emailCliente,
                            Endereco: this.enderecoCliente,
                            UF: this.estadoCliente,
                            Municipio: this.cidadeCliente
                        }

                        let dados = {
                            Cliente: dadosCliente,
                            Complementares: this.dadosComplementos,
                            Contatos: this.contatos
                        }
                        axios.post(`api/v2/clientes/SalvarCliente`,dados).then(response => {
                                if(response.data.Sucesso){

                                    this.promiseSalvaCliente(response.data);
                                }
                                else{
                                    this.$bvToast.toast(`O cliente já existe na base.`, {
                                        title: "Ops, algo deu errado...",
                                        toaster: "b-toaster-top-right",
                                        variant: "danger",
                                        solid: true,
                                        autoHideDelay: 5000
                                    });
                                }
                            }).catch(error => {
                                console.error(error);
                            })

                        //Timout apenas para teste de
                        setTimeout(() => {

                        }, 3000); // 1000 milissegundos = 1 segundos
                    }
                    if(this.lixosIds.length > 0){
                        let ids = Object.assign({}, ...this.lixosIds.map((item, index) => ({ [`ids[${index}]`]: item })));
                        axios.delete('api/v2/clientes/DeletarContatos', {
                            params: ids
                        }).then(() => {
                            this.promiseDeletaContato(true);
                        }).catch(error =>{
                            console.error(error);
                            this.$bvToast.toast(`Houve um erro ao tentar salvar o (s) contato (s).`, {
                                title: "Ops, algo deu errado...",
                                toaster: "b-toaster-top-right",
                                variant: "danger",
                                solid: true,
                                autoHideDelay: 5000
                            });
                        })
                    }else{
                        this.promiseDeletaContato(true);
                    } 
                        

                    if(ContatosComId.length > 0){
                        
                        axios.put(`api/v2/clientes/EditarContatos`,ContatosComId).then(() => {
                            this.promiseEditaContato(true);
                        }).catch(error => {
                            console.error(error);
                            this.$bvToast.toast(`Houve um erro ao tentar salvar o (s) contato (s).`, {
                                title: "Ops, algo deu errado...",
                                toaster: "b-toaster-top-right",
                                variant: "danger",
                                solid: true,
                                autoHideDelay: 5000
                            });
                        })
                    }else{
                        this.promiseEditaContato(true);
                    } 
                        
                }
            },
            cancelar(){
                this.$refs["novoClienteModal"].hide();
                this.limparCampos();
            },
            separarColunas(dados) {
                let result = [];
                let c = 0;
                let quantidade = Math.ceil(dados.length / 2)
                for (var i = 0; i < quantidade; i++) {
                    result.push(dados.slice(c, c + 2));
                    c += 2;
                }
                return result;
            },

            limparCampos() {
                this.clienteId = null;
                this.dadosComplementos = null;
                this.isSending = false;
                this.contatos = [];
                this.nomeCliente = null;
                this.cnpj_cpfCliente = null;
                this.telefoneCliente = null;
                this.contatoPrincipal = {};
                this.lixosIds =[];
                this.emailCliente = null;
                this.enderecoCliente = null;
                this.estadoCliente = null;
                this.cidadeCliente = null;
            },

            removerContato(index) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o contato?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        if (this.contatos[index].Id) {
                            this.lixosIds.push(this.contatos[index].Id);
                        }
                        if (this.contatos.length >= 1) {
                            this.contatos.splice(index, 1);
                        }
                    }
                })
            },
            buscarContatos(clienteId){
                axios.get(`api/v2/clientes/buscarcontatos/${clienteId}`).then(response => {
                    this.contatos = response.data.Contatos;
                    var cttPrincipal = response.data.ContatoPrincipal;
                    if(clienteId){
                        this.contatoPrincipal = {
                            Id : cttPrincipal.Id ?? null,
                            Email : cttPrincipal.Email ?? '',
                            Telefone : cttPrincipal.Telefone ?? '',
                            Nome : cttPrincipal.Nome ?? '',
                            Cargo : cttPrincipal.Cargo ?? '',
                            ContatoPrincipal : true
                        };
                    }
                })
            }
        }
    }


</script>

<!--=======================================================================================================-->

<style scoped>
    #tituloCttPrincipal{
        margin-left: 12px;
    }
    .titleModal {
        color: #737373;
    }

    #novo-ClienteModal input[type=text], select {
        opacity: 1;
        background-color: #F5F2F2;
        border: 1px #737373 solid;
        min-width: 100%;
    }

        #novo-ClienteModal input[type=text]:focus {
            background-color: #F5F2F2;
        }

    .data-field {
        color: #737373;
    }

    #botaoSalvar:disabled {
        opacity: 0.5;
    }
    .addButtom {
        padding-left: 40px;
        padding-right: 40px;
        background-color: #F5F2F2;
        border: 1px #737373 solid;
        color: #737373;
    }
    #add-option-btn{
        cursor:pointer;
        padding-top:0.7%;
        padding-bottom:0.7%
    }
    #linhaContato{
        border-top: 1px solid #737373;
    }
    #containerContatoPrincipal{
        border: 1px solid black;
        padding-bottom: 15px;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 2.5vh;
        margin-top: 10px;
    }
    .caixaContato{
        border: 1px solid black;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 2.5vh;
        margin-top: 10px;
        padding-top: 10px;
    }
    .checkPrincipal{
        width: 15px;
        margin-top: 3px;
        margin-right: 3px;
    }
    .labelContato{
        font-weight: bold;
		font-size: 13px;
		margin: 0;
    }
    .caixaContatoPrincipal{
        display: flex;
        margin-top: 14px;
        align-items: center;
    }
    .caixaExclusao{
        text-decoration: underline;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 14px;
        font-size: 13px;
        font-weight: bold;
    }
    .labelExclusao{
        margin-top:1px;
        margin-bottom:0px;
        cursor: pointer;
    }
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background-color: #E8E8E8;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--cinza-4);
      border-radius:10px;}
    
</style>